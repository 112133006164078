import React from "react";
import { PageSections } from "@happy-rabbit/gatsby-sanity-cms";
import { imageFromPageSection } from "../../utils/pages";
import { resolvePageSection } from "../PageSections";
import SEO from "../SEO";

const ProductCategoryView = props => {
  const { productCategoryPage } = props;
  const { productCategory, productSubCategory } = productCategoryPage;
  const { title } = productSubCategory || productCategory || {};

  const productCategoryDescription = title ? {
    en: title.en ? `Discover our ${title.en}. Buy online with fast shipping and free returns. ` : null,
    sv: title.sv ? `Upptäck våra ${title.sv}. Köp online med snabb frakt och fria returer. ` : null,
  } : null;
  const pageSectionImage = imageFromPageSection(productCategoryPage.pageSections[0]);
  const productCategoryTitle = title ? {
    en: title.en ? `${title.en} - Buy DePalma Workwear ${title.en} online` : null,
    sv: title.sv ? `${title.sv} - Köp DePalma Workwear ${title.sv} online` : null,
  } : null;

  return (
    <>
      <SEO
        pageFields={{
          description: productCategoryDescription,
          image: pageSectionImage,
          title: productCategoryTitle,
          titleTemplate: '%s',
        }}
      />

      <PageSections pageSections={productCategoryPage.pageSections} resolvePageSection={resolvePageSection} />
      {/* <div className="">
        {productCategoryPage.pageSections.map(renderPageSection)}
      </div> */}
    </>
  );
};

export default ProductCategoryView;
