import React, { useMemo } from "react";
import { graphql } from "gatsby";

import Layout from "../parts/Layout";
import { useMetadata } from "../queries/metadata";
import ProductCategoryView from "../parts/ProductCategoryView";
import { CMSProvider, ThemesProvider } from "@happy-rabbit/gatsby-sanity-cms";


const ProductCategoryPageTemplate = props => {
  const {
    data,
    pageContext,
    path,
  } = props;

  const site = data.allSanitySite.edges[0].node;
  const productCategoryPage = data.allSanityProductCategoryPage.edges[0].node;
  const productPageVariants = useMemo(() => data.allSanityProductPageVariant.edges.map(edge => edge.node), [data.allSanityProductPageVariant]);

  const { pageConfig, pageDesign } = productCategoryPage;

  const { lang } = pageContext;
  const siteMetadata = useMetadata();

  const {
    themeReferences: themes,
  } = site || {};

  return (
    <CMSProvider context={{ ...pageContext, pageConfig, page: productCategoryPage, path, productPageVariants, site, siteMetadata }}>
      <ThemesProvider themes={themes}>
        <Layout lang={lang} pageDesign={pageDesign}>
          <ProductCategoryView productCategoryPage={productCategoryPage} />
        </Layout>
      </ThemesProvider>
    </CMSProvider>
  );
};

export const pageQuery = graphql`
query($siteId: String!, $productCategoryPageId: String!, $productSubCategoryId: String!) {
  allSanitySite (filter: {_id: {eq: $siteId}}) {
    edges {
      node {
        _id

        defaultCurrencyCode
        slug {
          current
        }
        themeReferences {
          ...SanityThemeFragment
        }
      }
    }
  }

  allSanityProductCategoryPage (filter: {_id: {eq: $productCategoryPageId}}) {
    edges {
      node {
        ...SanityProductCategoryPageFragment
      }
    }
  }

  allSanityProductPageVariant (filter: {discontinued: {ne: true}, product: {productSubCategory: {_id: {eq: $productSubCategoryId}}}, hidden: { ne: true }}) {
    edges {
      node {
        ...SanityProductPageVariantPreviewFragment
      }
    }
  }
}
`;

export default ProductCategoryPageTemplate;
